/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBtYZhWydjSU59QFfKBCb4-R20P2Zni_-0",
  "appId": "1:772184408361:web:504a07743cbced929e3738",
  "authDomain": "schooldog-i-roanokecity-al.firebaseapp.com",
  "measurementId": "G-GYVEYQ2P4M",
  "messagingSenderId": "772184408361",
  "project": "schooldog-i-roanokecity-al",
  "projectId": "schooldog-i-roanokecity-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-roanokecity-al.appspot.com"
}
